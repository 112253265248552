.view-login {
  height: 100%;

  .container {
    height: 100%;
  }

  .row {
    height: 100%; display: flex; align-items: center;
  }
}

.login {
  background: #e64662;
  padding: 2rem;
  border-radius: 5px;
  color: #fff;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;

  label {
    width: 80%;
    float: left;
    text-align: left;
    font-weight: normal;
  }

  input {
    width: 100%;
    padding: 5px 10px;
    border: none;
    border-bottom: 2px solid #fff;
    background: none;
    box-shadow: none;
    outline: none;
  }

  button {
    margin-top: 30px;
    border-radius: 5px;
    width: 100%;
    color: #e64662;
    background: #fff;
    border: 2px solid #fff;
    font-weight: bold;
    font-size: 18px !important;

    &:hover {
      background: none;
      color: #fff;
      border: 2px solid #fff;
    }
  }

  p {
    text-align: center;
    opacity: 0.7;
  }

  .well {
    border-radius: 0;
    border: #ccc;
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.2);
  }

  &__support {
    margin-top: 1rem;
    background: #fff;
    padding: 1rem;
    border-radius: 5px;
    list-style: none;
    padding: 2rem;
  }

  &__support-item {
    width: 100%;
    text-align: center;
  }

  &__support-link {
    color: #e64662;

    &:hover {
      color: #e64662;
      text-decoration: underline;
    }
  }
}