@import "./katapult_login/com_users/_all-com_users";

html, body {
  height: 100%
}

body {
  background-image: url(/templates/katapult_login/img/bg.jpeg);
  background-size: cover;
  background-position: top right;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}

.logout button {
  width: 100%;
}

label {
  font-weight: normal;
}
button {
  font-size: 18px !important;
}

::placeholder {
  color:    #fff;
  opacity: 0.7;
}
 
.alert-warning {
  color: #e64662;
  background-color: #fff;
  border-color: #fff;
}
