.view-reset {
  height: 100%;

  .container {
    height: 100%;
  }

  .row {
    height: 100%; display: flex; align-items: center;
  }
}

.reset {
  background: #e64662;
  padding: 2rem;
  border-radius: 5px;
  color: #fff;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;

  label {
    display: none;
  }

  input {
    width: 100%;
    padding: 5px 10px;
    border: none;
    border-bottom: 2px solid #fff;
    background: none;
    box-shadow: none;
    outline: none;
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  button {
    margin-top: 30px;
    border-radius: 5px;
    width: 100%;
    color: #e64662;
    background: #fff;
    border: 2px solid #fff;
    font-weight: bold;
    font-size: 18px !important;
    max-width: 250px;

    &:hover {
      background: none;
      color: #fff;
      border: 2px solid #fff;
    }
  }

  p {
    opacity: 0.7;
    margin-bottom: 2rem;
  }

  .well {
    border-radius: 0;
    border: #ccc;
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.2);
  }

}